<template>
    <div>
        <Table :columns="tableColumns" :data="tableData" border></Table>
        <Page :total="total"
              :current="currentPage"
              @on-change="handleCurrentChange"
              @on-page-size-change="handlePageSize"
              prev-text="上一页"
              next-text="下一页"
              ref="footer"
              show-sizer style="float: right;"/>
    </div>
</template>
<script>
import tablePageMixin from '_m/tablePage.mixin'
import { cacheStorage } from '@/utils'
import bus from '@/utils/vueBus'
export default {
    mixins: [tablePageMixin],
    data() {
        return {
            tableData: [],
            getRequestUrl: '',
            loading: false
        }
    },
    computed: {
        tableColumns () {
            let columns = []
            columns.push({
                title: '文件',
                minWidth: 120,
                align: 'left',
                render: (h, params) => {
                    const { row } = params
                    return h('Button', {
                        props: {
                            type: 'text',
                            loading: row.$isLoading
                        },
                        attrs: {
                            href: row.fileName,
                        },
                        on: {
                            click: () => {
                                this.$set(row, '$isLoading', false)
                                this.handleDownload(row)
                            },
                        },
                    }, row.fileName)
                }
            })
            return columns
        }
    },
    created() {
        this.getTable()
    },
    methods: {
        getTable() {
            this.$http.get('{{charge-evcs}}/evcs/log/m/gateway/file', {
                params: {
                }
            }).then((response) => {
                const { code, data } = response
                if (code === 1) {
                    console.log('ee', data.file)
                    for (let i of data.file) {
                        this.tableData.push({
                            fileName: i
                        })
                    }
                    console.log('打印数据', this.tableData)
                    bus.$off('requestUrl')
                    bus.$on('requestUrl', (res) => {
                        this.getRequestUrl = res
                        console.log('看见没', this.getRequestUrl)
                    })
                }
            })
        },
        handleDownload(val) {
            this.$set(val, '$isLoading', true)
            this.$http.get('{{charge-evcs}}/evcs/log/m/gateway/file' + val.fileName, {
                params: {},
                responseType: 'blob',
                timeout: 600000,
            }).then((res) => {
                const blob = new Blob([res], {}) //保存为xlsx格式的excel文件
                const objectUrl = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.style = 'display: none'
                a.href = objectUrl
                // a.download = '客户导出表'
                let date = new Date()
                a.download = val.fileName
                a.click()
                document.body.removeChild(a)
            }).finally(() => {
                this.$set(val, '$isLoading', false)
            })
        }
    }
}
</script>
